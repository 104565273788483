<template>
  <div class="my-second jiang bgcolor">
      <Head :title="$t('mine.mysalaary')" :show="true" ></Head>
      <div class="common-box jiang-box">
          <div class="jiang-list" v-if="list.length>0">
              <div class="jiang-item">
                    <el-table
                        :data="list"   
                        class="app-table"
                    >
                        <!-- <af-table-column width="auto" type="index" prop="index"   label="#" ></af-table-column> -->
                        <af-table-column :label="$t('home.time')"  prop="time" ></af-table-column>
                        <!-- <af-table-column :label="$t('jiang.id_name')"   prop="uid_nickname" ></af-table-column> -->
                        <af-table-column :label="'ID'"   prop="uid_nickname" ></af-table-column>

                        <af-table-column :label="$t('jiang.gold_category')"  prop="type" ></af-table-column>
                        <af-table-column :label="$t('jiang.gold_grade')"  prop="rankname"  ></af-table-column>
                        <af-table-column :label="$t('jiang.gold_precent')"  prop="bonus_rate"    >
                            <template  slot-scope="scope">
                                {{scope.row.bonus_rate | number_format}}
                            </template>
                        </af-table-column>
                        <af-table-column  :label="$t('jiang.gold_pv')"   prop="total_amount">
                            <template  slot-scope="scope">
                                {{scope.row.total_amount | number_format}}
                            </template>
                        </af-table-column>
                    </el-table>
              </div>
          </div>

          <div v-else class="no-box" >
                <img class="juan-no" src="../../../assets/images/jiang-no.png" alt="">
                <p class="no-text">{{$t('wallet.no')}}{{$t('mine.reward')}}</p>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    mounted(){
        this.$ajax.bonuspvlog()
        .then(res=>{
            if(res.code==200){
                this.list = res.data.list;
            }
        })
    },
    data(){
        return {
            list:[
            ]
        }
    }
}
</script>
